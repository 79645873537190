// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

import baseStyles from './TechPlate.scss'

type Props = {
  title: string,
  children: any,
  color?: string,
  ...StyleProps
}

const TechPlate = ({ styles, title, children, color }: Props) => (
  <div className={classNames(styles.root, color && styles['is-' + color])}>
    <div className={styles.header}>
      <h3 className={styles.title}>{title}</h3>
    </div>
    <div className={styles.body}>
      {children}
    </div>
  </div>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TechPlate)
