// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import AboutTeaser from './../AboutTeaser'
import IntroTeaser from '../../IntroTeaser'
import TechnologiesWall from '../../TechnologiesWall'

import messages from './TechnologiesPage.lang'
import { StaticImage } from 'gatsby-plugin-image'

const introImage = (
  <StaticImage
    src="../../../images/pictures/technologies_intro_image.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)

const TechnologiesPage = () => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <AboutTeaser title={formatMessage(messages.pageTitle)} />
      <IntroTeaser
        title={formatMessage(messages.introTitle)}
        copy={
          <div
            dangerouslySetInnerHTML={{__html: formatMessage(messages.introInfo).replaceAll("'", '')}}
          />
        }
        image={introImage}
        fullWidth
      />
      <TechnologiesWall />
    </div>
  )
}

export default TechnologiesPage
