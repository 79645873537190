// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import TechPlate from './TechPlate/TechPlate'

import messages from './TechnologiesWall.lang'
import baseStyles from './TechnologiesWall.scss'
import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable max-len */
// DevOps logos
const logoDevOps1 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-docker.jpg' alt='Web technology @freshcells - Docker' title='Web technology @freshcells - Docker' objectFit='contain' className='image' />
const logoDevOps2 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-kubernetes.jpg' alt='Web technology @freshcells - Kubernetes' title='Web technology @freshcells - Kubernetes' objectFit='contain' className='image' />
const logoDevOps3 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-helm.jpg' alt='Web technology @freshcells - Helm' title='Web technology @freshcells - Helm' objectFit='contain' className='image' />
const logoDevOps4 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-flux.jpg' alt='Web technology @freshcells - Flux' title='Web technology @freshcells - Flux' objectFit='contain' className='image' />
// const logoDevOps5 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-cloudhosting.jpg' alt='Web technology @freshcells - CloudHosting' title='Web technology @freshcells - CloudHosting' objectFit='contain' className='image' />
const logoDevOps6 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-digital-ocean.jpg' alt='Web technology @freshcells - DigitalOcean' title='Web technology @freshcells - DigitalOcean' objectFit='contain' className='image' />
const logoDevOps7 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-grafana.jpg' alt='Web technology @freshcells - Grafana' title='Web technology @freshcells - Grafana' objectFit='contain' className='image' />
const logoDevOps8 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-prometheus.jpg' alt='Web technology @freshcells - Prometheus' title='Web technology @freshcells - Prometheus' objectFit='contain' className='image' />
const logoDevOps9 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-bitbucket.jpg' alt='Web technology @freshcells - Bitbucket' title='Web technology @freshcells - Bitbucket' objectFit='contain' className='image' />
const logoDevOps10 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-jenkins.jpg' alt='Web technology @freshcells - Jenkins' title='Web technology @freshcells - Jenkins' objectFit='contain' className='image' />
const logoDevOps11 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-yarn.jpg' alt='Web technology @freshcells - Yarn' title='Web technology @freshcells - Yarn' objectFit='contain' className='image' />
const logoDevOps12 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-aws.jpg' alt='Web technology @freshcells - AWS' title='Web technology @freshcells - AWS' objectFit='contain' className='image' />
const logoDevOps13 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-open-telekom-cloud.jpg' alt='Web technology @freshcells - Open Telekom Cloud' title='Web technology @freshcells - Open Telekom Cloud' objectFit='contain' className='image' />
const logoDevOps14 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-azure.jpg' alt='Web technology @freshcells - Azure' title='Web technology @freshcells - Azure' objectFit='contain' className='image' />
const logoDevOps15 = <StaticImage src='../../images/logos-technologies/devops/web-technology-at-freshcells-google-cloud.jpg' alt='Web technology @freshcells - Google Cloud' title='Web technology @freshcells - Google Cloud' objectFit='contain' className='image' />

// Backend logos
const logoBackend1 = <StaticImage src='../../images/logo/contentstack.svg' alt='Web technology @freshcells - Contentstack' title='Web technology @freshcells - Contentstack' objectFit='contain' className='image imageContentstack' />
const logoBackend2 = <StaticImage src='../../images/logo/strapi-business-partner.png' alt='Web technology @freshcells - Strapi' title='Web technology @freshcells - Strapi' objectFit='contain' className='image imageStrapi' />
const logoBackend3 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-node-js.jpg' alt='Web technology @freshcells - Node.js' title='Web technology @freshcells - Node.js' objectFit='contain' className='image' />
const logoBackend4 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-graphql.jpg' alt='Web technology @freshcells - GraphQL' title='Web technology @freshcells - GraphQL' objectFit='contain' className='image' />
const logoBackend5 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-apollo.jpg' alt='Web technology @freshcells - Apollo' title='Web technology @freshcells - Apollo' objectFit='contain' className='image' />
const logoBackend6 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-php8.jpg' alt='Web technology @freshcells - PHP 8' title='Web technology @freshcells - PHP 8' objectFit='contain' className='image' />
const logoBackend7 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-rest-api.jpg' alt='Web technology @freshcells - REST API' title='Web technology @freshcells - REST API' objectFit='contain' className='image' />
const logoBackend8 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-spring.jpg' alt='Web technology @freshcells - Spring' title='Web technology @freshcells - Spring' objectFit='contain' className='image' />
const logoBackend9 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-java.jpg' alt='Web technology @freshcells - Java' title='Web technology @freshcells - Java' objectFit='contain' className='image' />
const logoBackend10 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-postgresql.jpg' alt='Web technology @freshcells - PostgreSQL' title='Web technology @freshcells - PostgreSQL' objectFit='contain' className='image' />
const logoBackend11 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-mysql.jpg' alt='Web technology @freshcells - MySQL' title='Web technology @freshcells - MySQL' objectFit='contain' className='image' />
const logoBackend12 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-couchbase.jpg' alt='Web technology @freshcells - Couchbase' title='Web technology @freshcells - Couchbase' objectFit='contain' className='image' />
const logoBackend13 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-elastic.jpg' alt='Web technology @freshcells - Elastic' title='Web technology @freshcells - Elastic' objectFit='contain' className='image' />
const logoBackend14 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-saber-synxis.jpg' alt='Web technology @freshcells - Sabre SynXis' title='Web technology @freshcells - Sabre SynXis' objectFit='contain' className='image' />
const logoBackend15 = <StaticImage src='../../images/logos-technologies/backend/bewotec.png' alt='Web technology @freshcells - Bewotec' title='Web technology @freshcells - Bewotec' objectFit='contain' className='image imageBewotec' />
const logoBackend16 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-amadeus.jpg' alt='Web technology @freshcells - Amadeus' title='Web technology @freshcells - Amadeus' objectFit='contain' className='image' />
const logoBackend17 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-peakwork.jpg' alt='Web technology @freshcells - Peakwork' title='Web technology @freshcells - Peakwork' objectFit='contain' className='image' />
const logoBackend18 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-rabbitmq.jpg' alt='Web technology @freshcells - RabbitMQ' title='Web technology @freshcells - RabbitMQ' objectFit='contain' className='image' />
const logoBackend19 = <StaticImage src='../../images/logos-technologies/backend/web-technology-at-freshcells-postgraphile.jpg' alt='Web technology @freshcells - Postgraphile' title='Web technology @freshcells - Postgraphile' objectFit='contain' className='image' />

// Frontend logos
const logoFrontend1 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-react.jpg' alt='Web technology @freshcells - React' title='Web technology @freshcells - React' objectFit='contain' className='image' />
const logoFrontend2 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-typescript.jpg' alt='Web technology @freshcells - Typescript' title='Web technology @freshcells - Typescript' objectFit='contain' className='image' />
const logoFrontend3 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-nextjs.jpg' alt='Web technology @freshcells - Next.js' title='Web technology @freshcells - Next.js' objectFit='contain' className='image' />
const logoFrontend4 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-storybook.jpg' alt='Web technology @freshcells - Storybook' title='Web technology @freshcells - Storybook' objectFit='contain' className='image' />
const logoFrontend5 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-sass.jpg' alt='Web technology @freshcells - Sass' title='Web technology @freshcells - Sass' objectFit='contain' className='image' />
const logoFrontend6 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-cypress.jpg' alt='Web technology @freshcells - Cypress' title='Web technology @freshcells - Cypress' objectFit='contain' className='image' />
const logoFrontend7 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-jest.jpg' alt='Web technology @freshcells - Jest' title='Web technology @freshcells - Jest' objectFit='contain' className='image' />
const logoFrontend8 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-google-optimize.jpg' alt='Web technology @freshcells - Google Optimize' title='Web technology @freshcells - Google Optimize' objectFit='contain' className='image' />
const logoFrontend9 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-babel.jpg' alt='Web technology @freshcells - Babel' title='Web technology @freshcells - Babel' objectFit='contain' className='image' />
const logoFrontend10 = <StaticImage src='../../images/logos-technologies/frontend/web-technology-at-freshcells-webpack.jpg' alt='Web technology @freshcells - Webpack' title='Web technology @freshcells - Webpack' objectFit='contain' className='image' />

// Auth logos
const logoAuth1 = <StaticImage src='../../images/logos-technologies/auth/web-technology-at-freshcells-keycloak.jpg' alt='Web technology @freshcells - Keycloak' title='Web technology @freshcells - Keycloak' objectFit='contain' className='image' />
const logoAuth2 = <StaticImage src='../../images/logos-technologies/auth/web-technology-at-freshcells-jwt.jpg' alt='Web technology @freshcells - JWT' title='Web technology @freshcells - JWT' objectFit='contain' className='image' />
const logoAuth3 = <StaticImage src='../../images/logos-technologies/auth/web-technology-at-freshcells-super-tokens.jpg' alt='Web technology @freshcells - SuperTokens' title='Web technology @freshcells - SuperTokens' objectFit='contain' className='image' />
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const TechnologiesWall = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
      <div className={styles.plates}>
        <div className={styles.column}>
          <TechPlate
            title='Dev Ops, Hosting / Deployments'
            color='accent'
            styles={styles.plate}
          >
            {logoDevOps1}
            {logoDevOps2}
            {logoDevOps3}
            {logoDevOps4}
            {logoDevOps11}
            {logoDevOps7}
            {logoDevOps8}
            {logoDevOps9}
            {logoDevOps10}
            {logoDevOps6}
            {logoDevOps12}
            {logoDevOps13}
            {logoDevOps14}
            {logoDevOps15}
          </TechPlate>
        </div>
        <div className={styles.column}>
          <TechPlate
            title='Backend'
            color='primary'
            styles={styles.plate}
          >
            {logoBackend1}
            {logoBackend2}
            {logoBackend3}
            {logoBackend4}
            {logoBackend5}
            {logoBackend6}
            {logoBackend7}
            {logoBackend8}
            {logoBackend9}
            {logoBackend10}
            {logoBackend11}
            {logoBackend12}
            {logoBackend19}
            {logoBackend13}
            {logoBackend18}
            {logoBackend14}
            {logoBackend15}
            {logoBackend17}
            {logoBackend16}
          </TechPlate>
        </div>
        <div className={styles.column}>
          <TechPlate
            title='Frontend'
            color='success'
            styles={styles.plate}
          >
            {logoFrontend1}
            {logoFrontend2}
            {logoFrontend3}
            {logoFrontend4}
            {logoFrontend5}
            {logoFrontend6}
            {logoFrontend7}
            {logoFrontend8}
            {logoFrontend9}
            {logoFrontend10}
          </TechPlate>
          <TechPlate
            title='Auth'
            color='tertiary'
            styles={styles.plate}
          >
            {logoAuth1}
            {logoAuth2}
            {logoAuth3}
          </TechPlate>
        </div>
      </div>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TechnologiesWall)
